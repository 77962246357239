<template>
	<main class="page-container" style="padding: 0">
		<div class="page-header page-header-sub" style="border-bottom: 1px solid #ebebeb">
			<div class="page-component" style="padding: 55px 0px 30px">
				<!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
				<div v-if="isMobile" class="box_select">
					<button type="button" id="select_btn" @click="click">소담상회</button>
					<ul id="select_list">
						<li v-for="(item, idx) in menus[0].children" :key="idx">
							<a :href="item.path">- {{ item.title }}</a>
						</li>
					</ul>
				</div>
				<!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
				<!-- <div v-if="isMobile" class="section-notice-arrow">
          <a href="/playground2?ctgrCode=1012001" class="btn-arrow">
            <i class="icon-arrow-prev">이전 페이지</i>
          </a>
          <button type="button" class="btn-arrow disabled">
              <i class="icon-arrow-next">다음 페이지</i>
          </button>
        </div> -->
				<div class="page-header-title" style="text-align: center">
					<h2 class="title title_sub"><img src="../../../assets/img/sodam/sodam_title1.png" alt="소담상회" /></h2>
					<p class="description" style="font-size: 35px">“소상공인의 이야기와 제품을 담은 공간”</p>
				</div>
			</div>
		</div>

		<!-- 소담상회 소개 -->
		<div class="page-component">
			<div class="sodam_wrap">
				<!-- <section class="sodam_title">
          <h2 class="title">
            <img src="../../../assets/img/sodam/sodam_title.png" alt="소담상회">
          </h2>
          <p>“소상공인의 이야기와 제품을 담은 공간”</p>
        </section> -->
				<section class="business_introduce">
					<p class="subtit">사업소개</p>
					<div class="business_content">
						<p v-if="isMobile">
							소상공인의 디지털 전환을 위해 민관이 협업하여 <br />
							만든 소상공인 전용 O2O 플래그십 스토어입니다. <br />
							오프라인 공간에서 상품 판매, 체험을 통하여 <br />
							소비자에게 재미를 제공하고, 스마트 기기 활용, <br />
							온라인 연계 상품 판매를 통하여 O2O 판로지원을 <br />하고 있습니다.
						</p>
						<p v-else>
							소상공인의 디지털 전환을 위해 민관이 협업하여 만든 소상공인 전용 O2O 플래그십 스토어입니다. <br />
							오프라인 공간에서 상품 판매, 체험을 통하여 소비자에게 재미를 제공하고, 스마트 기기 활용, 온라인 연계 상품 판매를 통하여 O2O 판로지원을 하고 있습니다.
						</p>
					</div>
				</section>
				<section class="apply">
					<div class="space_introduce">
						<p class="subtit" style="position: relative; z-index: 100">운영기관 및 공간 소개</p>
						<div class="space_content">
							<div class="left">
								<div class="swiper img_list">
									<img src="../../../assets/img/sodam/sodam_img05.jpg" alt="" />
								</div>
								<div class="box_text ideas" style="position: relative; z-index: 100">
									<h4>소담상회</h4>
									<div class="text">
										<span class="dot">인사점</span>
										<span>&#45; 수공예 전문 O2O 스토어</span>
										<span class="address">&nbsp; 서울시 종로구 인사동길 44 쌈지길 본관1층/4층, 별관2~3층</span>										
									</div>
								</div>
							</div>
							<div class="left">
								<div class="swiper img_list">
									<img src="../../../assets/img/sodam/sodam_img06.jpg" alt="" />
								</div>
								<div class="box_text" style="position: relative; z-index: 100">
									<h4>소담상회</h4>
									<div class="text">
										<!-- <span class="dot">한남점</span>
										<span>&#45; 복합문화 체험공간</span>
										<span v-if="isMobile" class="address"> &nbsp; 서울시 용산구 이태원로 294 블루스퀘어 <br />&nbsp; L층(블루관), 네모홀(네모관) </span>
										<span v-else class="address"> &nbsp; 서울시 용산구 이태원로 294 블루스퀘어 L층(블루관), 네모홀(네모관) </span> -->
										<span class="dot">서교점</span>
										<span>&#45; F&B, 소공인 양성 공간</span>
										<span class="address">&nbsp; 서울시 마포구 홍익로 5길 31 1~5층</span>
									</div>
								</div>
							</div>
						</div>
						<div class="space_background"></div>
					</div>
					<div class="apply_content">
						<p class="subtit">지원 내용</p>
						<div class="box_text">
							<p v-if="isMobile">
								&#45; 소담상회 내 상품 전시/판매 및 온라인 연계판매<br />
								&#45; 온라인 마케팅<br />
								&#45; 착한 혜택지원(수수료, 쿠폰지원 등)<br />
								&#45; 교육/컨설팅<br />
								&#45; 홍보/프로모션 참여 등
							</p>
							<p v-else>소담상회 내 상품 전시/판매 및 온라인 연계판매, 온라인 마케팅, 착한 혜택지원(수수료, 쿠폰지원 등), 교육/컨설팅, 홍보/프로모션 참여 등</p>
							<p class="asterisk">* 각 수행기관 별 지원내용 상이함</p>
						</div>
					</div>
					<div class="apply_target">
						<p class="subtit">지원 대상</p>
						<div class="box_text">
							<p>온라인 진출을 희망하는 모든 소상공인</p>
							<p v-if="isMobile" class="asterisk">* 필수서류 :<br />소상공인확인서, 국세완납증명서, 지방세완납증명서</p>
							<p v-else class="asterisk">* 필수서류 : 소상공인확인서, 국세완납증명서, 지방세완납증명서</p>
						</div>
					</div>
					<div class="apply_step">
						<p class="subtit">지원 절차</p>
						<ul class="apply_box_step">
							<li>
								<p>신청,접수 <span>(3월~수시)</span></p>
							</li>
							<li>
								<p>모집 <span>(3월~수시)</span></p>
							</li>
							<li>
								<p>지원 <span>(3월~12월)</span></p>
							</li>
						</ul>
						<p class="asterisk">* 각 수행기관 별 사업 신청 방법 및 모집 일정 상이함</p>
					</div>
				</section>
			</div>
		</div>
		<div class="sodam_wrap" id="sme-we">
			<section class="thumbnail_wrap">
				<ul class="thumbnail_list">
					<li>
						<div class="thumbnail_img">
							<img src="../../../assets/img/sodam/sodam_thum04.jpg" alt="" />
						</div>
						<div class="thumbnail_content">
							<h5 class="thumbnail_tit">소담상회</h5>
							<p class="thumbnail_store">인사점</p>
							<span class="thumbnail_text">수공예 전문 O2O 스토어</span>
						</div>
						<div class="btn_group">
							<a href="https://www.idus.com/w/event/regular/ad764b9b-9f5c-4fa1-bfcb-f68f8639d4d4#/" class="" title="사업안내">
								<img src="../../../assets/img/sodam/btn_guide.png" alt="사업안내" />
							</a>
							<a href="https://map.naver.com/v5/entry/place/1844059951?c=14135876.2947536,4519460.9203111,13,0,0,0,dh&placePath=%2Fhome%3Fentry=plt" class="" title="위치안내">
								<img src="../../../assets/img/sodam/btn_location.png" alt="위치안내" />
							</a>
							<a href="https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=202403050300&sprtBizTrgtYn=N" class="" title="신청하기">
								<img src="../../../assets/img/sodam/btn_apply.png" alt="신청하기" />
							</a>
						</div>
					</li>
					<li>
						<div class="thumbnail_img">
							<img src="../../../assets/img/sodam/sodam_thum05.jpg" alt="" />
						</div>
						<div class="thumbnail_content">
							<h5 class="thumbnail_tit">소담상회</h5>
							<p class="thumbnail_store">서교점</p>
							<span class="thumbnail_text">F&B, 소공인 양성 공간</span>
						</div>
						<div class="btn_group">
							<a href="https://www.idus.com/w/event/regular/ad764b9b-9f5c-4fa1-bfcb-f68f8639d4d4#/" class="" title="사업안내">
								<img src="../../../assets/img/sodam/btn_guide.png" alt="사업안내" />
							</a>
							<a href="https://map.naver.com/v5/entry/place/1358125051?c=14128767.6017546,4516564.6804060,13,0,0,0,dh&placePath=%2Fhome&entry=plt" class="" title="위치안내">
								<img src="../../../assets/img/sodam/btn_location.png" alt="위치안내" />
							</a>
							<a href="https://www.iduscraftlab.com/" class="" title="신청하기">
								<img src="../../../assets/img/sodam/btn_apply.png" alt="신청하기" />
							</a>
						</div>
					</li>
					<!-- <li>
						<div class="thumbnail_img">
							<img src="../../../assets/img/sodam/sodam_thum03.jpeg" alt="" />
						</div>
						<div class="thumbnail_content">
							<h5 class="thumbnail_tit">소담상회</h5>
							<p class="thumbnail_store">한남점</p>
							<span class="thumbnail_text">복합문화 체험공간</span>
						</div>
						<div class="btn_group">
							<a href="https://events.interpark.com/exhibition?exhibitionCode=220330005" class="" title="사업안내">
								<img src="../../../assets/img/sodam/btn_guide.png" alt="사업안내" />
							</a>
							<a href="https://map.naver.com/v5/entry/place/1232637907?c=14137852.1823193,4514766.5345379,13,0,0,0,dh&placePath=%2Fhome%3Fentry=plt" class="" title="위치안내">
								<img src="../../../assets/img/sodam/btn_location.png" alt="위치안내" />
							</a> -->
							<!-- <a href="https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=202403050200&sprtBizTrgtYn=N" class="" title="신청하기">
								<img src="../../../assets/img/sodam/btn_apply.png" alt="신청하기" />
							</a> -->
						<!-- </div>
					</li> -->
				</ul>
			</section>
		</div>
		<!-- // 소담상회 소개 -->
	</main>
	<!-- end::page-container(#온라인 진출 배움터) -->
</template>

<script>
import { mapGetters } from 'vuex';
import menus from '../../../assets/js/menus';

export default {
	computed: {
		...mapGetters('common', ['isMobile']),
	},
	data: () => ({
		//모바일 중메뉴 데이터 (20220714 - hib)
		menus: menus.filter((x) => x.code === 'studio'),
	}),
	methods: {
		/* 중메뉴이동 (20220714 - hib) */
		click() {
			let element = document.getElementById('select_list');
			element.classList.toggle('show_list');
			let element02 = document.getElementById('select_btn');
			element02.classList.toggle('active');
		},
	},
};
</script>
